
import { User, CurrentUser } from '@/types';
import Vue from '@/vueTyped';
import { shortAddressFromComponents } from '@/util.location';
import PostsList from '@/components/PostsList.vue';

export default Vue.extend({
    name: 'UserDetail',
    metaInfo: {
        title: 'Profile',
        meta: [{
            name: 'robots',
            content: 'noindex',
        }],
    },
    components: {
        PostsList,
    },
    props: {
        userId: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            user: null as User | CurrentUser | null,
        };
    },
    computed: {
        // isCurrentUser(): boolean {
        //     const { currentUser } = this.$store.state.account;
        //     return currentUser !== null && this.user?.id === currentUser.id;
        // },
        shortAddress(): string {
            if (!this.user) {
                return '';
            }
            if (!(this.user as CurrentUser).addressComponents) {
                return this.user.shortAddress;
            }
            return shortAddressFromComponents((this.user as CurrentUser).addressComponents);
        }
    },
    watch: {
        userId: {
            immediate: true,
            async handler(this: any, userId) {
                const { data } = await this.$store.dispatch('fetchUser', { id: userId });
                this.user = data;
            }
        },
    },
});
