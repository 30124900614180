
import Vue from '@/vueTyped';
import CmsContent from '@/components/CmsContent.vue';
import FourOhFour from '@/pages/404.vue';
import { PrismicDocument } from '@/types';

export default Vue.extend({
    name: 'CmsContentPage',

    metaInfo(): any {
        return {
            title: this.document?.data.title[0].text ?? (this.notFound ? 'Not found' : ''),
        };
    },

    components: {
        CmsContent,
        FourOhFour,
    },

    props: {
        collectionUid: {
            type: String,
            default: '',
        },

        pageUid: {
            type: String,
            default: '',
        },

        hideTitle: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            document: null as PrismicDocument | null,
            notFound: false,
        };
    },

    methods: {
        routeFor(navDocument: PrismicDocument, page: { uid: string }) {
            const firstPage = navDocument.data.pages[0].page;
            return {
                name: 'cms_content_page',
                params: {
                    collection: navDocument.uid,
                    page: page.uid === firstPage.uid ? null : page.uid,
                },
            };
        },

        getActiveRoute(navDocument: PrismicDocument) {
            const currentParams = this.$router.currentRoute.params;
            return navDocument.data.pages.find((page: { page: { uid: string } }) => {
                const params = this.routeFor(navDocument, page.page).params;
                return params.collection === currentParams.collection && (params.page ?? null) === (currentParams.page ?? null);
            });
        },
    },
});

