
import Vue from '@/vueTyped';
import { Investigation } from '@/types';
import AsyncImage from '@/components/AsyncImage.vue';

export default Vue.extend({
    name: 'InvestigationCard',
    components: {
        AsyncImage,
    },
    props: {
        investigation: {
            type: Object as () => Investigation,
            default: null,
        },
        featured: Boolean,
        loading: {
            type: Boolean,
            default: false,
        },
    },
});
