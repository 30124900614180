
import { Investigation, CONTENT_ID } from '@/types';
import Vue from '@/vueTyped';
import CmsContent from '@/components/CmsContent.vue';
import InvestigationCard from '@/components/InvestigationCard.vue';

export default Vue.extend({
    name: 'Investigations',
    metaInfo: {
        title: 'Investigations',
        meta: [
            {
                vmid: 'description',
                name: 'description',
                content: 'Pick an investigation that our partners are actively researching and add sightings from your own backyard, neighborhood or city.',
            },
            {
                property: 'og:description',
                content: 'Pick an investigation that our partners are actively researching and add sightings from your own backyard, neighborhood or city.',
                vmid: 'og:description'
            },
            {
                property: 'twitter:description',
                content: 'Pick an investigation that our partners are actively researching and add sightings from your own backyard, neighborhood or city.',
                vmid: 'twitter:description'
            },
        ],
    },
    components: {
        CmsContent,
        InvestigationCard,
    },
    data() {
        return {
            contentType: CONTENT_ID.INVESTIGATION_INDEX,
        };
    },
    computed: {
        investigations(): Investigation[] {
            return this.$store.state.investigations.items;
        },
        investigationsLoaded(): boolean {
            return this.$store.state.investigations.asyncStatus === 'success';
        },
        featured(): Investigation[] {
            const localFeatured = this.investigations.filter(investigation => investigation.featured && investigation.regionId);
            const nonlocalFeatured = this.investigations.filter(investigation => investigation.featured && !investigation.regionId);
            return localFeatured.concat(nonlocalFeatured);
        },
        nonFeatured(): Investigation[] {
            return this.investigations.filter((investigation: Investigation) => {
                return !this.featured.includes(investigation);
            });
        },
    },
    created() {
        this.$store.dispatch('fetchInvestigations');
    },
});
