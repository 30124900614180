
import { Investigation } from '@/types';
import Vue from '@/vueTyped';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default Vue.extend({
    name: 'InvestigationDetail',
    metaInfo(): any {
        return {
            title: this.investigation ? this.investigation.name : 'Investigations',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.investigation ? this.investigation.cta : '',
                },
                {
                    property: 'og:description',
                    content: this.investigation ? this.investigation.cta : '',
                    vmid: 'og:description'
                },
                {
                    property: 'twitter:description',
                    content: this.investigation ? this.investigation.cta : '',
                    vmid: 'twitter:description'
                },
            ],
        };
    },
    components: {
        LoadingIndicator,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            possibleLangs: ['en', 'es'],
        };
    },
    computed: {
        investigation(): Investigation {
            return this.$store.state.investigations.selectedItem;
        },
        contentIndex(): number {
            const translationsPresent = this.investigation.name.split(/\s+\/\s+/).length;

            if (translationsPresent > 1) {
                const index = this.possibleLangs.indexOf(this.$i18n.locale);

                if (index !== -1) {
                    return index;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        }
    },
    mounted() {
        this.$store.dispatch('fetchAndSetInvestigation', { id: this.id });
    },
});
