
import Vue from '@/vueTyped';
import SightingPreviewSlider from '@/components/SightingPreviewSlider.vue';
import { VExpansionPanels, VExpansionPanel, VExpansionPanelHeader, VExpansionPanelContent } from 'vuetify/lib';
import { CurrentUser, Post, PostParameters } from '@/types';
import orderBy from 'lodash/orderBy';
import { trackActionsPageEvent } from '@/tracking';
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { aggregatePostData, createQueryParams } from '@/util.posts';
import uniqBy from 'lodash/uniqBy';
import { ISEECHANGE_TRENDS_USER_ID } from '@/config';

// Using meteorological seasons from
// https://www.timeanddate.com/calendar/aboutseasons.html
function getSeason(date: Date, latitude: number): string {
    const MAR = 3;
    const JUN = 6;
    const SEP = 9;
    const DEC = 12;

    const month = date.getMonth() + 1; // Months are zero-indexed.
    const northern = latitude >= 0;

    // Let's consider the first half of March to be winter. It's still pretty cold.
    const isFirstHalfOfMarch = month === MAR && date.getDate() <= 15;

    if (month < MAR || month === DEC || isFirstHalfOfMarch) {
        return northern ? 'winter' : 'summer';
    } else if (month < JUN) {
        return northern ? 'spring' : 'autumn';
    } else if (month < SEP) {
        return northern ? 'summer' : 'winter';
    } else {
        return northern ? 'autumn' : 'spring';
    }
}

export default Vue.extend({
    name: 'ActionsPage',

    metaInfo: {
        title: 'Actions',
    },

    components: {
        SightingPreviewSlider,
        VExpansionPanels,
        VExpansionPanel,
        VExpansionPanelHeader,
        VExpansionPanelContent,
        MarkdownOutput,
        LoadingIndicator,
    },

    data() {
        return {
            loading: 0,
            openPanelIndex: 0, // Note: this will be unpredictable unless all the panels are loaded at the same time.
            priorYearsPosts: [] as Post[],
            trendsPosts: [] as Post[],
            postsWithRecentComments: [] as string[],
            today: typeof this.$route.query.today === 'string' ? new Date(this.$route.query.today) : new Date(),
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        distanceNearUser(): string {
            const NEARBY = { IMPERIAL: '50mi', METRIC: '80km' };
            const units = this.currentUser?.userSettings?.preferredSystemOfMeasure ?? 'IMPERIAL';
            return NEARBY[units];
        },

        partnerPanels(): [string, any][] {
            return Object.entries(this.$t('actionsPage.partners')).filter(panelNameAndTranslation => {
                const { investigationId, regionId } = panelNameAndTranslation[1];
                return this.$store.state.investigations.items.find(i => i.id === investigationId && i.regionId === regionId);
            });
        },

        season(): string {
            const latitude = this.currentUser?.lat;

            if (latitude) {
                return getSeason(this.today, latitude);
            } else {
                return '';
            }
        },
    },

    watch: {
        openPanelIndex(openPanelIndex: number) {
            // Note, the index is by order of presence, not DOM placement.
            const openPanel = (this.$refs.panels as Vue & { items: Vue[] }).items[openPanelIndex];
            if (openPanel?.$el instanceof HTMLElement && openPanel.$el.dataset.name) {
                trackActionsPageEvent('Opened Actions Page Panel', { 'Panel Name': openPanel.$el.dataset.name });
            }
        }
    },

    mounted() {
        trackActionsPageEvent('Viewed Actions Page');

        const pageRoute = this.$router.currentRoute;

        setTimeout(() => {
            if (this.$router.currentRoute === pageRoute) {
                this.$store.dispatch('updateSettings', {
                    lastViewedActionsPage: new Date().toISOString(),
                });
            }
        }, 3000);

        this.findPostsFromPriorYears();
        this.findTrendsPosts();
        this.findPostsWithRecentComments();
    },

    methods: {
        async findPostsFromPriorYears() {
            try {
                this.loading += 1;

                this.priorYearsPosts = [];

                if (this.currentUser) {
                    const yearsToFetch = [-1, -2, -3, -4, -5].map(ago => this.today.getFullYear() + ago);
                    const DAY_RANGE = 30;

                    const baseParams: PostParameters = {
                        limit: 5,
                        lngLat: [this.currentUser.lng, this.currentUser.lat],
                        distance: this.distanceNearUser,
                        withImage: true,
                    };

                    const postsByYears = await Promise.all(yearsToFetch.map(async year => {
                        const start = new Date(year, this.today.getMonth(), this.today.getDate() - Math.ceil(DAY_RANGE / 2));
                        const end = new Date(year, this.today.getMonth(), this.today.getDate() + Math.ceil(DAY_RANGE / 2));

                        const params: PostParameters = {
                            ...baseParams,
                            fromDate: start.toISOString(),
                            toDate: end.toISOString(),
                        };

                        const paramsString = createQueryParams(params);
                        const { data } = await this.$store.getters.apiClient.get(`/posts?${paramsString}`);
                        const posts = await aggregatePostData(this.$store, data.posts, data.photos, data.investigations, data.users, data.comments, data.weatherUnits);
                        // When adding `withImage`, the API returns duplicates.
                        return uniqBy(posts, 'id');
                    }));

                    this.priorYearsPosts = postsByYears.flat();
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.loading -= 1;
            }
        },

        async findTrendsPosts() {
            try {
                this.loading += 1;
                const { data } = await this.$store.getters.apiClient.get(`/posts?user=${ISEECHANGE_TRENDS_USER_ID}&sort=observedAt+DESC&limit=5`);
                this.trendsPosts = await aggregatePostData(this.$store, data.posts, data.media, data.investigations, data.users, data.comments, data.weatherUnits);
            } catch (error) {
                console.error(error);
            } finally {
                this.loading -= 1;
            }
        },

        async findPostsWithRecentComments() {
            try {
                this.loading += 1;
                const { data } = await this.$store.getters.apiClient.get('/comments?limit=40&sort=createdAt+DESC');
                const releventPosts = (data.posts as Post[]).filter(post => post.photos.length !== 0 && post.comments.length >= 2);
                const sortedPosts = orderBy(releventPosts, 'createdAt', 'desc');
                this.postsWithRecentComments = sortedPosts.map(post => post.id).slice(0, 5);
            } catch (error) {
                console.error(error);
            } finally {
                this.loading -= 1;
            }
        },

        trackCtaClick(panelName: string) {
            trackActionsPageEvent('Clicked Actions Page CTA', { 'Panel Name': panelName });
        },

        trackPreviewScroll(panelName: string) {
            trackActionsPageEvent('Scrolled Actions Page Post Carousel', { 'Panel Name': panelName });
        },

        trackPreviewPostClick(panelName: string, post: Post) {
            trackActionsPageEvent(`Clicked Post from Actions Page Post Carousel`, { 'Panel Name': panelName, 'Post ID': post?.id });
        },

        trackStoryClick(panelName: string) {
            // Including the panel name is redundant, but we track this as a CTA.
            trackActionsPageEvent('Clicked Stories Link from Actions Page', { 'Panel Name': panelName });
        },

        share(panelName: string) {
            this.trackCtaClick(panelName);

            this.$store.dispatch('shareContent', {
                path: '/',
                title: 'ISeeChange | Community climate journal',
                text: 'Share your experiences and collect data to investigate our environment and help our communities change.',
            });
        },
    },
});
