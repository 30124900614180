
import Vue from 'vue';
import { Investigation, RootState } from '@/types';
import MarkdownOutput from '@/components/MarkdownOutput.vue';

export default Vue.extend({
    components: { MarkdownOutput },

    computed: {
        investigation(): Investigation {
            return (this.$store.state as RootState).investigations.selectedItem;
        },

        tweakedActionDescription(): string | null {
            if (this.investigation.actionDescriptionMarkdown) {
                // This is stored in the database, it's easier to change this here than on the back end.
                // We'll take care of this properly when this content makes its way to a CMS.
                return this.investigation.actionDescriptionMarkdown.replace(
                    'https://www.iseechange.org/img/partners/nola-ready.jpeg',
                    'https://images.prismic.io/iseechange/06cf588d-bed2-425b-96ab-a59bc461aa64_NOLA+Ready+Logo.png?auto=compress,format&h=150'
                ).replace(
                    'src="https://www.youtube.com/embed',
                    'title="Embedded YouTube video" src="https://www.youtube.com/embed'
                );
            } else {
                return null;
            }
        },
    },
});
