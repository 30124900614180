
import { Story } from '@/types';
import Vue from '@/vueTyped';
import StoryCard from '@/components/StoryCard.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default Vue.extend({
    metaInfo: {
        title: 'Stories',
    },

    components: {
        StoryCard,
        LoadingIndicator,
    },

    computed: {
        stories(): Story[] {
            return this.$store.getters.storiesByDate;
        },

        primaryStory(): Story {
            return this.stories[0];
        },

        secondaryStories(): Story[] {
            return this.stories.slice(1, 3);
        },

        allOtherStories(): Story[] {
            return this.stories.slice(3);
        },
    },

    async mounted() {
        await this.$store.dispatch('fetchStories');
        // await this.loadMoreStories();
    },

    methods: {
        async loadMoreStories() {
            this.$store.dispatch('fetchStories', true);
        }
    },
});
