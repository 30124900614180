
import Vue from '@/vueTyped';
import { Comment, Post } from '@/types';
import SlippyCarousel from '@/components/SlippyCarousel.vue';
import ViewDetector from '@/components/ViewDetector.vue';

interface MessageMap {
    [id: string]: (Post | Comment)[];
}

export default Vue.extend({
    name: 'SightingPreviewSlider',

    components: {
        SlippyCarousel,
        ViewDetector,
    },

    props: {
        notSquare: Boolean,

        posts: {
            type: Array as () => (Post | string)[], // Posts and post IDs
            default: () => [],
        },

        messages: {
            type: Number,
            default: 1,
        },

        showMessageMetadata: {
            type: Boolean,
            default: false,
        },

        postCta: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            loading: false,
            error: null as Error | null,
            allPosts: [] as Post[],
        };
    },

    computed: {
        messagesByPost(): MessageMap {
            const result: MessageMap = {};
            for (const post of this.allPosts) {
                result[post.id] = [post, ...post.commentObjs].slice(0, this.messages);
            }
            return result;
        },
    },

    watch: {
        posts: {
            deep: true,
            handler() {
                this.refresh();
            },
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async refresh() {
            this.loading = true;

            const fulfilledPosts = await Promise.all(this.posts.map(async id => {
                if (typeof id === 'string') {
                    const response = await this.$store.dispatch('fetchPostById', { id });
                    return response.data;
                } else {
                    return id;
                }
            }));

            this.allPosts = fulfilledPosts.filter(Boolean);

            if (this.allPosts.length === 0) {
                this.error = new Error('No posts fetched');
            }

            this.loading = false;
        },
    },
});
