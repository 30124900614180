
import Vue from '@/vueTyped';

export default Vue.extend({
  name: 'StoryCard',
  props: {
      imageUrl: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      url: {
        type: String,
        default: '',
      },
      author: {
        type: String,
        default: '',
      },
      date: {
        type: String,
        default: '',
      },
      imageAlign: {
        type: String,
        default: '',
      },
  },
  computed: {
    backgroundStyle(): {backgroundImage: string, backgroundSize: string} {
        let img = '';
        if (this.imageUrl) {
            if (this.imageUrl.includes('.png')) {
                img = this.imageUrl.split('.png')[0] + '-800x425.png';
            }
            if (this.imageUrl.includes('.jpg')) {
                img = this.imageUrl.split('.jpg')[0] + '-800x425.jpg';
            }
            if (this.imageUrl.includes('.jpeg')) {
                img = this.imageUrl.split('.jpeg')[0] + '-800x425.jpeg';
            }
        }
        return {
            backgroundImage: `url(${img})`,
            backgroundSize: this.imageAlign || 'center center',
        };
    },
  },
  methods: { },
});
