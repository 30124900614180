
import { RootState } from '@/types';
import Vue from 'vue';
import PostsList from '@/components/PostsList.vue';

export default Vue.extend({
    name: 'InvestigationDetailPosts',
    components: {
        PostsList,
    },
    computed: {
        investigation() {
            return (this.$store.state as RootState).investigations.selectedItem;
        },
    },
});
